import React from 'react';
import dynamic from 'next/dynamic';
import {
  // General Elements
  ArticleBody,
  ArticleGraphController,
  Breadcrumbs,
  BreakingNewsBox,
  ChangeableElementGroup,
  ClickTrackerWrapper,
  Debug,
  ElementGroup,
  Embed,
  Enhancement,
  Error,
  AdSlot,
  AdSlotPrioritySelector,
  GridContainer,
  GridElement,
  HtmlElement,
  HtmlNode,
  HtzLink,
  Image,
  ImageGallery,
  Interactive,
  LinksBlock,
  List,
  Masthead,
  MobileListWrapper,
  Outbrain,
  Paragraph,
  PodcastEpisode,
  PromotionalCardElement,
  Quote,
  RelatedArticles,
  RssFeed,
  SeriesArticles,
  SpecialPromotions,
  TableScore,
  Tags,
  TmFooter,
  TopNews,
  Video,
  WrappedComments,
  withDfpSideEffect,
  MagazineRecipes,

  // List views
  Bender,
  Clampazzo,
  Farnsworth,
  Leela,
  Marco,
  Michelle,
  Spot,
  Zoidberg,
  ZoidbergLabels,

  // icons
  IconMarkerLogo,
  IconMarkerLogoTransparent,
  IconTheMarkerLabel,
  IconTheMarker,
  IconTmLoader,

  // utils
  ArticlesReadCounter,
  getElementsFactory,
  Chat,
} from '@haaretz/htz-components';

const ArticleBodyAudio = dynamic(() => import('../components/ArticleBodyAudio/ArticleBodyAudio'));

const fileUpload = dynamic(
  () => import('../components/FileUpload'),
  {
    ssr: false,
    loading: () => null,
  }
);

const MobileNewsletter = dynamic(() => import('../components/Newsletter/MobileNewsletter'), {
  loading: () => null,
  ssr: false,
});
const Newsletter = dynamic(() => import('../components/Newsletter/Newsletter'), {
  loading: () => null,
  ssr: false,
});
const PersonalNewsletter = dynamic(() => import('../components/Newsletter/PersonalNewsletter'), {
  loading: () => null,
  ssr: false,
});
const ResponsiveNewsletter = dynamic(() => import('../components/Newsletter/ResponsiveNewsletter'), {
  loading: () => null,
  ssr: false,
});

// Infographics
const VisualInfo = dynamic(
  () => import('../components/Infographics/VisualInfo'),
  { loading: () => null, }
);

const Questions = dynamic(
  () => import('../components/Infographics/Questions'),
  { loading: () => null, }
);

const Columns = dynamic(
  () => import('../components/Infographics/Columns'),
  { loading: () => null, }
);

const Timeline = dynamic(
  () => import('../components/Infographics/Timeline'),
  { loading: () => null, }
);

const Ids = dynamic(
  () => import('../components/Infographics/Ids'),
  { loading: () => null, }
);

const InfographicList = dynamic(
  () => import('../components/Infographics/InfographicList'),
  { loading: () => null, }
);

const tagsFromTagElement = ({ tagsList, }) => tagsList.map(x => x.contentName);

// eslint-disable-next-line react/prop-types
function MastheadLogo({ miscStyles, }) {
  return (
    <IconMarkerLogo
      attrs={{ 'aria-hidden': true, }}
      size={[ { until: 'l', value: 4, }, { from: 'l', value: 5, }, ]}
      color="primary"
      miscStyles={{
        display: 'block',
      }}
    />
  );
}
// eslint-disable-next-line react/prop-types
function MastheadLabelLogo() {
  return (
    <IconTheMarkerLabel
      attrs={{ 'aria-hidden': true, }}
      size={[ { until: 'l', value: 5, }, { from: 'l', value: 5.5, }, ]}
      color={[ 'bodyText', 'base', ]}
      fill="black"
      miscStyles={{
        display: 'block',
      }}
    />
  );
}

const elements = new Map([
  [ 'HtmlNode', HtmlNode, ],
  [ 'Enhancement', Enhancement, ],
  /* HeaderSlots components */
  [ 'PageNavigation', Masthead, ],
  // [ 'com.tm.PageTitle', Breadcrumbs, ],

  /* MainSlot components */
  // [ 'com.htz.StandardArticle', ArticleBody, ],
  [ 'Outbrain', Outbrain, ],
  [ 'CommentsPlaceHolder', WrappedComments, ],

  /* FooterSlot components */
  [ 'PageFooter', TmFooter, ],

  /* ArticleBody components */
  [ 'CardRichTextElement', PromotionalCardElement, ],
  [ 'EmbedRichTextElement', Embed, ],
  [ 'Embed', Embed, ],
  [ 'FileElementRichTextElement', fileUpload, ],
  [ 'interactive', Interactive, ],
  [ 'InteractiveRichTextElement', Interactive, ],
  // [ 'com.polobase.audioEmbed', ArticleBodyAudio, ],
  // [ 'articleNarration', ArticleBodyAudio, ],
  [ 'articleHeaderAudio', ArticleBodyAudio, ],
  [ 'QuoteRichTextElement', Quote, ],
  // [ 'linksBlock', LinksBlock, ],
  [ 'core_link_Link', HtzLink, ],
  // [ 'com.polobase.quickNewsletterRegistration', MobileNewsletter, ],
  [ 'RelatedArticles', RelatedArticles, ],
  [ 'SeriesArticles', SeriesArticles, ],
  [ 'PodcastEpisode', PodcastEpisode, ],
  [ 'htz_link_Link', SpecialPromotions, ],
  [ 'TenRecipes', MagazineRecipes, ],

  // [ 'com.polobase.MemberEventElement', ArticlesReadCounter, ],

  /*
   * This is only a temporary solution to avoid hitting preformance, and SHOULD BE AVOIDED as much as possible.
   * This creates a coupling between different concerns: (rendring components, accessing data)
   * but in this case as tags are deeply nested inside an array we use this to avoid searching them inside the entire page data
   * TODO: expose just the tags in graphql, then replace this temprary solution with one that fetchs the data from graphql
   */
  // [
  //   'tagsElement',
  //   withDfpSideEffect(Tags, {
  //     sideEffect: tagsElement => dfpTargeting.setTags(tagsFromTagElement(tagsElement)),
  //   }),
  // ],
  [
    'GraphRichTextElement',
    // eslint-disable-next-line react/prop-types
    ({ assetId, ...props }) => (assetId ? (
    // eslint-disable-next-line react/prop-types
      <ArticleGraphController assetId={assetId} {...props} />
    ) : null),
  ],

  /* Misc components */
  [ 'GridItem', GridElement, ],
  [ 'Grid', GridContainer, ],
  [ 'image', Image, ],
  [ 'infographic', Image, ],
  [ 'htz_image_Image', Image, ],
  // [ 'com.tm.BlogImage', Image, ],
  // [ 'com.tm.ImageGalleryElement', ImageGallery, ],
  // [ 'gallery', ImageGallery, ],
  // [ 'video', Video, ],
  [ 'HtmlElementRichTextElement', HtmlElement, ],
  [ 'HtmlElement', HtmlElement, ],
  // [ 'com.tm.newsLetterQuickRegistrationRespAuto', ResponsiveNewsletter, ],
  [ 'RegistrationRichTextElement', Newsletter, ],
  // [ 'com.tm.newsLetterPersonal', PersonalNewsletter, ],
  // [
  //   'com.tm.ListElement',
  //   () => (
  //     <Debug>
  //       <p>Old list, NOT SUPPORTED</p>
  //     </Debug>
  //   ),
  // ],
  [ 'htz_list_List', List, ],
  // [ 'com.polobase.whtzMobileSiteListsWrapper', MobileListWrapper, ],
  [ 'SideBox', ChangeableElementGroup, ],
  [ 'HeaderNewsList', props => {
    if (props.kind === 'breakingNews') return <BreakingNewsBox {...props} />;
    return <TopNews {...props} />;
  }, ],
  [ 'topNews', TopNews, ],
  // [ 'com.tm.ElementGroup', ElementGroup, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'AdSlotRichTextElement', AdSlot, ],
  // [ 'com.polobase.ClickTrackerBannersWrapper', ClickTrackerWrapper, ],
  // [ 'com.tm.interactive.recipes10', MagazineRecipes, ],
  [ 'TableScore', TableScore, ],
  [ 'OnlifeFeed', RssFeed, ],
  // [ 'error', Error, ],
  [ null, null, ],

  // List views
  [ 'Bender', Bender, ],
  [ 'Farnsworth', Farnsworth, ],
  [ 'Leela', Leela, ],
  [ 'Zoidberg', Zoidberg, ],
  [ 'ZoidbergLabels', ZoidbergLabels, ],
  [ 'Clampazzo', Clampazzo, ],
  [ 'Marco', Marco, ],
  [ 'Michelle', Michelle, ],
  [ 'Spot', Spot, ],

  // Infographic
  [ 'VisualInfo', VisualInfo, ],
  [ 'QAndA', Questions, ],
  [ 'ColumnsModel', Columns, ],
  [ 'Timeline', Timeline, ],
  [ 'Ids', Ids, ],
  [ 'ListInfo', InfographicList, ],

  // Icons
  [ 'articleHeaderMetaLogo', IconTheMarker, ],
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'mastheadLabelLogo', MastheadLabelLogo, ],
  [ 'siteLogo', IconMarkerLogo, ],
  [ 'siteIconTransparent', IconMarkerLogoTransparent, ],
  [ 'loaderIcon', IconTmLoader, ],

]);

const getArticleElements = getElementsFactory(elements);

export default getArticleElements;
